/* eslint-disable @typescript-eslint/naming-convention */
import {SearchOptions} from '@Shared/enums/search-options';
import {SuggestionType} from '@Shared/enums/suggestion-type';
import {Suggestion} from '@Shared/interfaces/suggestion';
import {SuggestionFilter} from '@Shared/interfaces/suggestion-filter';
import {ReduceFiltersToTruthyLengthPipe} from '@Shared/pipes';

export class SearchFilter implements SuggestionFilter {
  buildingNames?: string[];
  minBathrooms?: number;
  maxBathrooms?: number;
  minBedrooms?: number;
  maxBedrooms?: number;
  minCapitalValue?: number;
  maxCapitalValue?: number;
  minDwellings?: number;
  maxDwellings?: number;
  minFloorArea?: number;
  maxFloorArea?: number;
  minLandArea?: number;
  maxLandArea?: number;
  minSaleDate?: Date;
  maxSaleDate?: Date;
  minSalePrice?: number;
  maxSalePrice?: number;
  minSettlementDate?: Date;
  maxSettlementDate?: Date;
  minRentalListedDate?: Date;
  maxRentalListedDate?: Date;
  minListingDate?: Date;
  maxListingDate?: Date;
  minListingPrice?: number;
  maxListingPrice?: number;
  minListingWithdrawnDate?: Date;
  maxListingWithdrawnDate?: Date;
  minCarSpaces?: number;
  maxCarSpaces?: number;
  minStreetNumber?: number;
  maxStreetNumber?: number;
  streetNumberParity?: number;
  minYearBuilt?: number;
  maxYearBuilt?: number;
  landUseCodes: string[] = [];
  landZoneCodes: string[] = [];
  mainSuggestion?: Suggestion;
  searchSpecifiedPropertiesOnly = false;
  owner?: string;
  owners: string[] = [];
  propertyTypeCodes: string[] = [];
  roofConstructionCodes: string[] = [];
  saleTenureCodes: string[] = [];
  streetIdentifiers: string[] = [];
  suburbIdentifiers: string[] = [];
  propertyIdentifiers: string[] = [];
  tenureCodes: string[] = [];
  wallConstructionCodes: string[] = [];
  excludePropertyIdentifier?: string;
  excludePropertyIdentifiers: string[] = [];
  hasActiveListing?: boolean;
  'distanceFilter.Distance'?: number;
  'distanceFilter.Latitude'?: number;
  'distanceFilter.Longitude'?: number;
  legalDescriptions?: string[] = [];
  searchOptions?: SearchOptions = SearchOptions.all;
  polygon?: string;
  mapZoneId?: string;

  constructor(obj?: Partial<SearchFilter>) {
    Object.assign(this, {...obj});
  }

  static doesFilterHaveValues(searchFilter?: SearchFilter): boolean {
    const pipe = new ReduceFiltersToTruthyLengthPipe();
    return pipe.transform(searchFilter) > 0;
  }

  initFromSuggestion(suggestion: Suggestion, showNewSearch = false): SearchFilter {
    const newSearchFilter = {
      ...this.resetBaseSearchFilter(),
      mainSuggestion: suggestion,
      propertyIdentifiers: suggestion.propertyIdentifier ? [suggestion.propertyIdentifier] : [],
      streetIdentifiers: suggestion.streetIdentifier ? [suggestion.streetIdentifier] : [],
      suburbIdentifiers: suggestion.suburbIdentifier ? [suggestion.suburbIdentifier] : [],
    };

    if (showNewSearch) {
      newSearchFilter.owners = suggestion.owner ? [suggestion.owner] : [];
    } else {
      newSearchFilter.buildingNames = suggestion.buildingName ? [suggestion.buildingName] : [];
      newSearchFilter.owner = suggestion.owner;      
    }

    return new SearchFilter(newSearchFilter);
  }

  applyAdditionalSuggestions(suggestions: Suggestion[]): SearchFilter {
    const searchFilter = new SearchFilter({
      ...this,
      streetIdentifiers: [],
      suburbIdentifiers: [],
    });

    const streetSuggestions = suggestions.filter((suggestion) => suggestion.suggestionType === SuggestionType.Street);
    const suburbSuggestions = suggestions.filter((suggestion) => suggestion.suggestionType === SuggestionType.Suburb);

    if (this.mainSuggestion?.streetIdentifier) {
      searchFilter.streetIdentifiers.push(this.mainSuggestion.streetIdentifier);
    }

    streetSuggestions.forEach((streetSuggestion) => {
      searchFilter.streetIdentifiers.push(streetSuggestion.streetIdentifier ?? '');
    });

    if (this.mainSuggestion?.suburbIdentifier) {
      searchFilter.suburbIdentifiers.push(this.mainSuggestion.suburbIdentifier);
    }

    suburbSuggestions.forEach((suburbSuggestion) => {
      searchFilter.suburbIdentifiers.push(suburbSuggestion.suburbIdentifier ?? '');
    });

    return searchFilter;
  }

  equals(searchFilter: SearchFilter): boolean {
    return JSON.stringify(this) === JSON.stringify(searchFilter);
  }

  resetBaseSearchFilter(): SearchFilter {
    return new SearchFilter({
      ...this,
      buildingName: undefined,
      owner: undefined,
      owners: [],
      streetIdentifiers: [],
      suburbIdentifiers: [],
      propertyIdentifiers: [],
    });
  }
}
