import {HttpErrorResponse} from '@angular/common/http';

export const handleApiMessage = (errorResponse: HttpErrorResponse): string | undefined => {
  if (!errorResponse.error) {
    return;
  }

  const error = errorResponse.error;
  let errorMessage;

  if (error.detail) {
    errorMessage = error.detail;
  }

  if (error.errors) {
    errorMessage = Object.keys(error.errors)
      .map((key) => error.errors[key])
      .join(', ');
  }

  return errorMessage ?? undefined;
};
