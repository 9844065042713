import {ApiHttpService} from '@Core/services/api-http/api-http.service';
import {Injectable} from '@angular/core';
import {map, Observable, ReplaySubject} from 'rxjs';
import {environment} from '@Env/environment';
import {UrlBuilder} from '@Shared/classes/url-builder/url-builder';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  currentUserPermissions = new ReplaySubject<string[]>(1);
  constructor(private readonly apiHttpService: ApiHttpService) {}

  hasPermission(requiredPermissions: string[]): Observable<boolean> {
    return this.currentUserPermissions.asObservable().pipe(
      map((grantedPermissions) => {
        if (!grantedPermissions?.length) {
          return false;
        }

        return !requiredPermissions.some((x) => !grantedPermissions.includes(x));
      })
    );
  }

  loadCurrentUserPermissions(): Observable<void> {
    return this.getCurrentUserPermissions().pipe(
      map((permissions) => {
        this.currentUserPermissions.next(permissions);
      })
    );
  }

  private getCurrentUserPermissions(): Observable<string[]> {
    const permissionsApiUrl = new UrlBuilder(environment.apiGatewayUri, 'pfl/api/v1/users/me/permissions').toString();
    return this.apiHttpService.get<string[]>(permissionsApiUrl);
  }
}
