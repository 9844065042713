import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, inject, Injectable, NgZone} from '@angular/core';
import {httpErrors} from '@Shared/constants/http-errors';
import {handleApiMessage} from '@Shared/helpers/error-message-helpers/api-error-message-helper';
import {HttpErrors} from '@Shared/interfaces/http-errors';
import {MonitoringService} from '@Core/services/monitoring/monitoring.service';
import {NotificationService} from '@Core/services/notifications/notification.service';
import {NotificationType} from '@Shared/enums/notification-type';
import {parseNumber} from '@Shared/helpers/number-helpers/number-helpers';
import {DOCUMENT} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService extends ErrorHandler {
  chunkErrorCountName = 'chunkErrorCount';
  tryMaximum = 3;

  document = inject(DOCUMENT);

  constructor(
    private monitoringService: MonitoringService,
    private notificationService: NotificationService,
    private ngZone: NgZone
  ) {
    super();
  }

  handleError(error: Error): void {
    this.monitoringService.logException(error);

    if (error instanceof HttpErrorResponse) {
      this.handleHttpError(error);
    } else {
      this.handleClientError(error);
    }
  }

  handleHttpError(error: HttpErrorResponse) {
    this.ngZone.run(() => {
      this.notificationService.openNotificationDialog(
        error.status === 500 ? NotificationType.internalServerError : NotificationType.error,
        handleApiMessage(error) ?? httpErrors[error.status as keyof HttpErrors] ?? 'An unknown error occurred!'
      );
    });
  }

  handleClientError(error: Error, customErrorMessage?: string) {
    if (error.message.toLowerCase().indexOf('chunkloaderror') !== -1) {
      const chunkErrorCount = sessionStorage.getItem(this.chunkErrorCountName);

      let errorCount = parseNumber(chunkErrorCount ?? 0) ?? 0;

      if (errorCount < this.tryMaximum) {
        errorCount += 1;

        sessionStorage.setItem(this.chunkErrorCountName, errorCount.toString());

        this.notificationService.openNotificationDialog(
          NotificationType.chunkError,
          'Detected old files. Refreshing to get the latest version...'
        );

        setTimeout(() => {
          this.document.defaultView?.location.reload();
        }, 4000);
      } else {
        this.notificationService.openNotificationDialog(
          NotificationType.chunkError,
          'Looks like there was a problem getting the latest version. Please close browser tab and reopen.'
        );
      }
    } else {
      this.notificationService.openNotificationDialog(NotificationType.error, customErrorMessage ?? error.message);
    }
  }
}
