export enum PropertySortLabel {
  alphabetical = 'Alphabetical',
  saleDate = 'Sale date',
  salePrice = 'Sale price',
  listingDate = 'Listing date',
  listedDate = 'Listed date',
  listingPrice = 'Listing price',
  weeklyRent = 'Weekly rent',
  distance = 'Distance',
  listingWithdrawnDate = 'Withdrawn On',
}
