import {ScrollStrategy, ScrollStrategyOptions} from '@angular/cdk/overlay';
import {Injectable} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {SnackbarComponent} from '@Shared/component-library/components';
import {Button} from '@Shared/component-library/components/atoms/buttons/action-button/button-types';
import {NotificationsDialogComponent} from '@Shared/components/organisms/dialogs/notifications-dialog/notifications-dialog.component';
import {MessageFormat} from '@Shared/enums/message-format';
import {NotificationType} from '@Shared/enums/notification-type';
import {ReportAction} from '@Shared/enums/report-action';
import {commonDialogConfig} from '@Shared/helpers/dialog-helpers/dialog-helpers';
import {NotificationData} from '@Shared/interfaces/notification-data';
import {SnackBarMessageAction, SnackBarMessageOptions} from '@Shared/interfaces/snackbar-message-options';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  scrollStrategy: ScrollStrategy;
  private readonly action = new Subject<SnackBarMessageAction>();
  private readonly snackbarMessageQueue: SnackBarMessageOptions[] = [];
  private processingSnackBarMessage = false;
  action$ = this.action.asObservable();
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private readonly scrollStrategyOptions: ScrollStrategyOptions
  ) {
    this.scrollStrategy = this.scrollStrategyOptions.noop();
  }

  openNotificationDialog(
    notificationType: NotificationType,
    message: string,
    width: string = '50rem',
    height: string = 'auto',
    id?: string
  ) {
    const options: MatDialogConfig<NotificationData> = {
      data: new NotificationData({
        notificationType,
        message,
      }),
      scrollStrategy: this.scrollStrategy,
      id,
      height,
      width,
      ...commonDialogConfig,
    };

    this.dialog.open<NotificationsDialogComponent>(NotificationsDialogComponent, options);
  }

  closeDialog(id: string) {
    this.dialog.getDialogById(id)?.close();
  }

  openMessageDialog(
    notificationType: NotificationType,
    message: string,
    title?: string,
    messageFormat: MessageFormat = MessageFormat.text,
    width: string = 'auto',
    height: string = 'auto',
    buttons?: Button[]
  ): MatDialogRef<NotificationsDialogComponent, NotificationType | ReportAction> {
    const data = new NotificationData({
      notificationType,
      message,
      title,
      messageFormat,
      buttons,
    });

    const options: MatDialogConfig<NotificationData> = {
      data,
      height,
      width,
      disableClose: true,
      hasBackdrop: true,
      closeOnNavigation: true,
      scrollStrategy: this.scrollStrategy,
    };

    return this.dialog.open(NotificationsDialogComponent, options);
  }

  openDialog(
    data: NotificationData,
    width: string = 'auto',
    height: string = 'auto'
  ): MatDialogRef<NotificationsDialogComponent, NotificationType | ReportAction> {
    const options: MatDialogConfig<NotificationData> = {
      data,
      height,
      width,
      disableClose: true,
      hasBackdrop: true,
      closeOnNavigation: true,
      scrollStrategy: this.scrollStrategy,
    };

    return this.dialog.open(NotificationsDialogComponent, options);
  }

  openSnackBarMessage(options: SnackBarMessageOptions) {
    return this.snackBar.openFromComponent(SnackbarComponent, {
      duration: options.countdown ? 4000 : undefined,
      data: options,
      panelClass: options.messageType,
    });
  }

  addToSnackBarMessageQueue(options: SnackBarMessageOptions) {
    this.snackbarMessageQueue.push(options);
    if (!this.processingSnackBarMessage) {
      this.displaySnackBar();
    }
  }

  actionClicked(action: SnackBarMessageAction) {
    if (!action) {
      return;
    }
    this.action.next(action);
  }

  private displaySnackBar() {
    const nextMessage = this.getNextSnackbarMessage();

    if (!nextMessage) {
      this.processingSnackBarMessage = false;
      return;
    }

    this.processingSnackBarMessage = true;

    this.openSnackBarMessage(nextMessage)
      .afterDismissed()
      .subscribe(() => {
        this.displaySnackBar();
      });
  }

  private getNextSnackbarMessage(): SnackBarMessageOptions | undefined {
    return this.snackbarMessageQueue.length ? this.snackbarMessageQueue.shift() : undefined;
  }
}
