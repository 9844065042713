/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-shadow */
export enum SuggestionType {
  Building = 'Building',
  Owner = 'Owner',
  PrimaryAddress = 'PrimaryAddress',
  Property = 'Property',
  Street = 'Street',
  Suburb = 'Suburb',
  Title = 'Title',
}
