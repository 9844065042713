@if (data.notificationType === notificationTypeRef.internalServerError) {
  <div class="dialog__container container">
    <mat-icon class="error__icon">warning</mat-icon>
    <h2>Error</h2>
    <p>A system error occurred while processing your request.</p>
    <p>
      You can contact support, go back and try again or
      <a
        [routerLink]="['/search']"
        routerLinkActive="router-link-active"
        mat-dialog-close
        >navigate to the home page</a
      >
    </p>
    <div class="button">
      <button
        mat-flat-button
        color="primary"
        (click)="close()"
      >
        Close
      </button>
    </div>
  </div>
}

@if (data.notificationType === notificationTypeRef.error) {
  <div class="dialog__container container">
    <mat-icon class="error__icon">warning</mat-icon>
    <h2>Error</h2>
    <p>
      {{ data.message }}
    </p>
    <div class="button">
      <button
        mat-flat-button
        color="primary"
        (click)="close()"
      >
        Close
      </button>
    </div>
  </div>
}

@if (data.notificationType === notificationTypeRef.chunkError) {
  <div class="dialog__container container">
    <mat-icon class="error__icon">warning</mat-icon>
    <h2>Error</h2>
    <p>
      {{ data.message }}
    </p>
  </div>
}

@if (data.notificationType === notificationTypeRef.success) {
  <div class="dialog__container container">
    <mat-icon class="success__icon">done</mat-icon>
    <h2>Success</h2>
    <p>{{ data.message }}</p>
    <button
      mat-flat-button
      color="primary"
      (click)="close()"
    >
      Close
    </button>
  </div>
}

@if (data.notificationType === notificationTypeRef.progress) {
  <div class="dialog__container">
    <h2>Please wait...</h2>
    <div class="progress">
      <mat-spinner></mat-spinner>
      <p>{{ data.message }}</p>
    </div>
  </div>
}

@if (data.notificationType === notificationTypeRef.delete) {
  <app-dialog>
    @if (data.title) {
      <h2 class="dialog__heading">
        {{ data.title }}
      </h2>
    }
    <div class="dialog__content">
      @if (data.errorBanner?.canShow) {
        <app-error-banner
          class="quantum-body-medium-regular"
          bannerIcon="warning"
          [title]="data.errorBanner?.title ?? ''"
          [text]="data.errorBanner?.text ?? ''"
        >
        </app-error-banner>
      }
      <p [innerHTML]="data.message">
        {{ data.message }}
      </p>
      <p class="warning__text">This action cannot be undone.</p>
    </div>
    <div class="dialog__primary">
      <app-button
        [mat-dialog-close]=""
        label="Cancel"
        appearance="secondary"
      ></app-button>
      <app-button
        [mat-dialog-close]="data.notificationType"
        status="error"
        prefix="delete"
        label="Delete"
      ></app-button>
    </div>
  </app-dialog>
}

@if (data.notificationType === notificationTypeRef.discard) {
  <app-dialog>
    <h2 class="dialog__heading">Discard Changes?</h2>
    <div class="dialog__content">If you discard changes without saving, your progress will be lost.</div>
    <div class="dialog__primary">
      <app-button
        [mat-dialog-close]=""
        label="Cancel"
        appearance="secondary"
      ></app-button>
      <app-button
        [mat-dialog-close]="data.notificationType"
        status="warn"
        prefix="assignment_turned_in"
        label="Discard"
      ></app-button>
    </div>
  </app-dialog>
}

@if (data.notificationType === notificationTypeRef.fileNameError) {
  <app-dialog>
    @if (data.title) {
      <h2 class="dialog__heading">
        {{ data.title }}
      </h2>
    }
    <div
      class="dialog__content"
      [innerHTML]="data.message"
    >
      <p>
        {{ data.message }}
      </p>
    </div>
    <div class="dialog__primary">
      <app-button
        [mat-dialog-close]=""
        label="Cancel"
        appearance="secondary"
      ></app-button>
      <app-button
        [mat-dialog-close]="data.notificationType"
        status="warn"
        prefix="warning"
        label="Replace"
      ></app-button>
    </div>
  </app-dialog>
}

@if (data.notificationType === notificationTypeRef.reportSuccess) {
  <app-dialog>
    <button
      mat-icon-button
      class="dialog__close"
      mat-dialog-close
    >
      <mat-icon>close</mat-icon>
    </button>
    @if (data.title) {
      <h2 class="dialog__heading">
        {{ data.title }}
      </h2>
    }
    <div
      class="dialog__content"
      [innerHTML]="data.message"
    >
      <p>
        {{ data.message }}
      </p>
    </div>
    <div class="dialog__primary">
      <app-button
        [mat-dialog-close]="reportActionRef.open"
        prefix="description"
        label="Open"
      ></app-button>
      <app-button
        [mat-dialog-close]="reportActionRef.save"
        prefix="file_upload"
        label="Save"
      ></app-button>
    </div>
  </app-dialog>
}

@if (data.notificationType === notificationTypeRef.primary) {
  <app-dialog>
    <div class="dialog__close">
      <button
        mat-icon-button
        mat-dialog-close
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
    @if (data.title) {
      <h2 class="dialog__heading">
        {{ data.title }}
      </h2>
    }
    <div
      class="dialog__content"
      [innerHTML]="data.message"
    >
      <p>
        {{ data.message }}
      </p>
    </div>
    <div class="dialog__primary">
      @if (data.buttons) {
        @for (button of data.buttons; track button) {
          <app-button
            [mat-dialog-close]="button.action"
            [status]="button.status"
            [prefix]="button.prefix ?? ''"
            [label]="button.label"
            [appearance]="button.appearance"
          ></app-button>
        }
      } @else {
        <button
          mat-flat-button
          color="primary"
          (click)="close()"
        >
          OK
        </button>
      }
    </div>
  </app-dialog>
}
