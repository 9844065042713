export enum UserStatusIds {
  pending = 1,
  active = 2,
  inactive = 3,
  deleted = 4,
}

export enum UserStatuses {
  pending = 'Pending',
  active = 'Active',
  inactive = 'Inactive',
  deleted = 'Deleted',
}
