import {Injectable, signal} from '@angular/core';
import {CurrentUserContext} from '@Shared/classes/user-context/current-user-context';
import {Observable, of} from 'rxjs';

export const currentUser = signal<CurrentUserContext>({} as CurrentUserContext);

@Injectable({
  providedIn: 'root',
})
export class UserContextService {
  getCurrentUser(): CurrentUserContext | null {
    const claims = JSON.parse(localStorage.getItem('id_token_claims_obj') as string);

    if (claims) {
      const user = new CurrentUserContext(
        claims.tenant,
        claims.sub,
        claims.given_name,
        claims.family_name,
        claims.email,
        claims.zoneinfo,
        claims.locale,
        claims.user_type
      );
      currentUser.set(user);
      return user;
    } else {
      return null;
    }
  }

  getCurrentUserPermissions(): Observable<string[]> {
    return of([]);
  }

  getCurrentUserTenantId(): string | null {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      return currentUser.tenantId;
    } else {
      return null;
    }
  }

  getCurrentUserType(): string | null {
    const currentUser = this.getCurrentUser();
    if (currentUser) {
      return currentUser.userType;
    } else {
      return null;
    }
  }

  currentUser$(): Observable<CurrentUserContext | null> {
    return of(this.getCurrentUser());
  }

  getCurrentUserTenantId$(): Observable<string | null> {
    return of(this.getCurrentUserTenantId());
  }
}
