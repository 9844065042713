import {SortConfig} from '@Env/interfaces/sort-config';
import {PropertySortKey} from '@Shared/enums/property-sort-key';
import {PropertySortLabel} from '@Shared/enums/property-sort-label';

export const sortOptions: SortConfig = {
  searchResults: {
    default: {
      label: PropertySortLabel.saleDate,
      active: PropertySortKey.saleDate,
      direction: 'desc',
    },
    options: [
      {
        label: PropertySortLabel.alphabetical,
        active: PropertySortKey.suburb,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.saleDate,
        active: PropertySortKey.saleDate,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.salePrice,
        active: PropertySortKey.salePrice,
        direction: '',
      },
    ],
  },
  sold: {
    default: {
      label: PropertySortLabel.saleDate,
      active: PropertySortKey.saleDate,
      direction: 'desc',
    },
    options: [
      {
        label: PropertySortLabel.saleDate,
        active: PropertySortKey.saleDate,
        direction: 'desc',
      },
      {
        label: PropertySortLabel.salePrice,
        active: PropertySortKey.salePrice,
        direction: '',
      },
      {
        label: PropertySortLabel.alphabetical,
        active: PropertySortKey.suburb,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.distance,
        active: PropertySortKey.distance,
        direction: '',
      },
    ],
  },
  forSale: {
    default: {
      label: PropertySortLabel.listingDate,
      active: PropertySortKey.listingStartDate,
      direction: 'desc',
    },
    options: [
      {
        label: PropertySortLabel.listingDate,
        active: PropertySortKey.listingStartDate,
        direction: 'desc',
      },
      {
        label: PropertySortLabel.listingPrice,
        active: PropertySortKey.listingPrice,
        direction: '',
      },
      {
        label: PropertySortLabel.alphabetical,
        active: PropertySortKey.suburb,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.distance,
        active: PropertySortKey.distance,
        direction: '',
      },
    ],
  },
  forLease: {
    default: {
      label: PropertySortLabel.listedDate,
      active: PropertySortKey.rentalListedDate,
      direction: 'desc',
    },
    options: [
      {
        label: PropertySortLabel.listedDate,
        active: PropertySortKey.rentalListedDate,
        direction: 'desc',
      },
      {
        label: PropertySortLabel.weeklyRent,
        active: PropertySortKey.weeklyListedPrice,
        direction: '',
      },
      {
        label: PropertySortLabel.alphabetical,
        active: PropertySortKey.suburb,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.distance,
        active: PropertySortKey.distance,
        direction: '',
      },
    ],
  },
  searchResultsForSale: {
    default: {
      label: PropertySortLabel.listingDate,
      active: PropertySortKey.listingStartDate,
      direction: 'desc',
    },
    options: [
      {
        label: PropertySortLabel.alphabetical,
        active: PropertySortKey.suburb,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.listingDate,
        active: PropertySortKey.listingStartDate,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.listingPrice,
        active: PropertySortKey.listingPrice,
        direction: '',
      },
    ],
  },
  searchResultsWithdrawn: {
    default: {
      label: PropertySortLabel.listingWithdrawnDate,
      active: PropertySortKey.listingWithdrawnDate,
      direction: 'desc',
    },
    options: [
      {
        label: PropertySortLabel.alphabetical,
        active: PropertySortKey.suburb,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.listingDate,
        active: PropertySortKey.listingStartDate,
        direction: 'asc',
      },
      {
        label: PropertySortLabel.listingPrice,
        active: PropertySortKey.listingPrice,
        direction: '',
      },
      {
        label: PropertySortLabel.listingWithdrawnDate,
        active: PropertySortKey.listingWithdrawnDate,
        direction: 'asc',
      },
    ],
  },
};
