import {NotificationService} from '@Core/services/notifications/notification.service';
import {SnackBarMessageOptions} from '@Shared/interfaces/snackbar-message-options';
import {Component, inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
})
export class SnackbarComponent {
  snackBarRef = inject(MatSnackBarRef);
  data: SnackBarMessageOptions = inject(MAT_SNACK_BAR_DATA);
  private readonly notificationService = inject(NotificationService);

  onActionClick() {
    if (!this.data.action) {
      return;
    }
    this.notificationService.actionClicked(this.data.action);
    this.snackBarRef.dismiss();
  }
}
