/* eslint-disable @typescript-eslint/naming-convention */
import {HttpErrors} from '@Shared/interfaces/http-errors';

export const httpErrors: HttpErrors = {
  400: 'Unable to process your request. Please try again',
  401: 'Token expired. Please log in again',
  403: 'You do not have permission to access this resource',
  408: 'The server timed out waiting for your request. Please try again',
  500: 'A system error occurred while processing your request. Please try again',
  503: 'Service is currently unavailable. Please try again later',
};
