import {Component, inject, Inject, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '@Core/services/authentication/authentication.service';
import {ProfileManagementService} from '@Core/services/profile-management/profile-management-service';
import {UserContextService} from '@Core/services/user-context/user-context.service';
import {ENVIRONMENT} from '@Env/environment.provider';
import {EnvironmentConfiguration} from '@Env/interfaces/environment-configuration';
import {ProfilePermissions} from '@Pages/user-management/enums/profile-permissions';
import {userTypes} from '@Shared/constants/user-types';
import {FeatureFlags} from '@Shared/enums/feature-flags';
import {MyProfileSummary} from '@Shared/interfaces/my-profile-summary';
import {Observable, Subject, takeUntil, tap} from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  profileSummary$?: Observable<Partial<MyProfileSummary>>;
  readonly profilePermissions = ProfilePermissions;
  isUserTypeAgent = true;
  knowledgeBaseUrl: string | undefined;
  #router = inject(Router);
  disableHover = false;
  logoWidth = '';
  logoHeight = '';
  logoSrc = '';
  readonly featureFlags = FeatureFlags;

  private readonly destroy$ = new Subject();

  constructor(
    private readonly profileManagementService: ProfileManagementService,
    private readonly authService: AuthenticationService,
    private userContextService: UserContextService,
    @Inject(ENVIRONMENT) private readonly environment: EnvironmentConfiguration
  ) {}

  ngOnInit(): void {
    this.profileSummary$ = this.profileManagementService.profileSummary;
    this.knowledgeBaseUrl = this.environment.knowledgeBaseUrl;
    this.logoSrc = this.environment.logoSrc;

    if (this.authService.isLoggedIn) {
      this.getUserType();
    } else {
      this.authService.tokenReceivedEvent$
        .pipe(
          tap(() => this.getUserType()),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  handleLogOut(): void {
    this.authService.logout();
  }

  getUserType() {
    const userType = this.userContextService.getCurrentUserType();
    if (userType && userType !== userTypes.realEstateAgent) {
      this.isUserTypeAgent = false;
    }
  }

  showUnderline(routes: string[]) {
    return routes.find((current) => this.#router.url.includes(current) && !this.disableHover) !== undefined;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
