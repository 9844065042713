<mat-toolbar>
  <button
    mat-icon-button
    [matMenuTriggerFor]="hamburger_menu"
    class="icon__hamburger"
    aria-label="Open hamburger menu"
  >
    <mat-icon class="mobile">menu</mat-icon>
  </button>

  <mat-menu
    class="account-menu"
    #hamburger_menu="matMenu"
  >
    @if (isUserTypeAgent) {
      <a
        class="nav-bar__link"
        mat-menu-item
        [routerLink]="['/dashboard']"
        routerLinkActive="router-link-active"
        aria-label="Link to Dashboard page"
      >
        <mat-icon>dashboard</mat-icon>
        <span>Dashboard</span>
      </a>
    }
    @if (isUserTypeAgent) {
      <a
        class="nav-bar__link"
        mat-menu-item
        [routerLink]="['/search']"
        routerLinkActive="router-link-active"
        aria-label="Link to Search page"
      >
        <mat-icon>search</mat-icon>
        <span>Property Search</span>
      </a>
    }

    <ng-container *appIsFeatureEnabled="featureFlags.cpmsEnabled">
      <ng-container *appHasPermission="[profilePermissions.viewClients]">
        <a
          [routerLink]="['/cpms/clients']"
          [ngClass]="{'router-link-active': showUnderline(['/cpms'])}"
          class="nav-bar__link"
          mat-menu-item
          routerLinkActive="router-link-active"
          aria-label="Link to CPMS Clients page"
          matTooltip="Manage Clients"
          id="manageClientsMenuItem"
        >
          <mat-icon>admin_panel_settings</mat-icon>
          <span>Manage Clients</span>
        </a>
      </ng-container>
    </ng-container>

    <ng-container *appHasPermission="[profilePermissions.manageUsers]">
      <a
        class="nav-bar__link"
        mat-menu-item
        [routerLink]="['/manage/users']"
        routerLinkActive="router-link-active"
        aria-label="Link to Admin Settings page"
        matTooltip="Admin Settings"
      >
        <mat-icon>manage_accounts</mat-icon>
        <span>Admin Settings</span>
      </a>
    </ng-container>

    @if (isUserTypeAgent) {
      <a
        class="nav-bar__link"
        mat-menu-item
        [routerLink]="['/profile']"
        routerLinkActive="router-link-active"
        aria-label="Link to Manage Profile page"
        matTooltip="Manage Profile"
      >
        <mat-icon>person</mat-icon>
        <span>Manage Profile</span>
      </a>
    }
    <hr />
    <button
      mat-menu-item
      aria-label="Sign out of your account"
      matTooltip="Sign out of your account"
      (click)="handleLogOut()"
    >
      <mat-icon>exit_to_app</mat-icon>
      <span>Sign Out</span>
    </button>
  </mat-menu>

  <a
    id="NexusLogoLink"
    [routerLink]="['/home']"
    mat-button
    class="nav-bar_logo nav-bar__link"
    aria-label="Link to Home page"
  >
    <img
      id="NexusLogo"
      [src]="logoSrc"
      alt="Nexus Logo"
    />
  </a>

  @if (isUserTypeAgent) {
    <a
      [routerLink]="['/dashboard']"
      routerLinkActive="router-link-active"
      mat-button
      class="nav-bar_icon nav-bar_icon--underline nav-bar__link"
      aria-label="Link to Dashboard page"
    >
      <mat-icon>dashboard</mat-icon>
      Dashboard
    </a>
  }
  @if (isUserTypeAgent) {
    <a
      [routerLink]="['/search']"
      routerLinkActive="router-link-active"
      mat-button
      class="nav-bar_icon nav-bar_icon--underline nav-bar__link"
      aria-label="Link to Search page"
    >
      <mat-icon>search</mat-icon>
      Property Search
    </a>
  }

  <span class="icon-spacer"></span>

  <div
    class="button-container"
    (mouseenter)="disableHover = true"
    (mouseleave)="disableHover = false"
  >
    @if (this.knowledgeBaseUrl) {
      <a
        [href]="knowledgeBaseUrl"
        target="_blank"
        rel="noopener noreferrer"
        mat-icon-button
        class="nav-bar_icon nav-bar_icon--underline nav-bar__link"
        aria-label="Open link to Knowledge base"
        matTooltip="Help"
        id="helpButton"
      >
        <mat-icon
          fontSet="material-icons-outlined"
          class="default__icon"
          >help</mat-icon
        >
      </a>
    }

    <ng-container *appIsFeatureEnabled="featureFlags.cpmsEnabled">
      <ng-container *appHasPermission="[profilePermissions.viewClients]">
        <a
          [routerLink]="['/cpms/clients']"
          [ngClass]="{'router-link-active': showUnderline(['/cpms'])}"
          class="nav-bar_icon nav-bar_icon--underline nav-bar__link"
          mat-icon-button
          routerLinkActive="router-link-active"
          aria-label="Link to CPMS Clients page"
          matTooltip="Manage Clients"
          id="manageClientsButton"
        >
          <mat-icon class="default__icon">admin_panel_settings</mat-icon>
        </a>
      </ng-container>
    </ng-container>

    <ng-container *appHasPermission="[profilePermissions.manageUsers]">
      <a
        [routerLink]="['/manage/users']"
        [ngClass]="{'router-link-active': showUnderline(['/manage/roles', '/manage/users'])}"
        mat-icon-button
        class="nav-bar_icon nav-bar_icon--underline nav-bar__link"
        aria-label="Link to Admin Settings page"
        matTooltip="Admin Settings"
      >
        <mat-icon class="default__icon">manage_accounts</mat-icon>
      </a>
    </ng-container>

    <button
      style="display: none"
      [matMenuTriggerFor]="notification_menu"
      mat-icon-button
      class="nav-bar_icon nav-bar_icon--underline"
      aria-label="Open notifications dropdown"
    >
      <mat-icon>notifications</mat-icon>
    </button>

    <mat-menu
      #notification_menu="matMenu"
      style="display: none"
    >
      <button mat-menu-item>
        <mat-icon>priority_high</mat-icon>
        <span>Notification 1</span>
      </button>
      <button mat-menu-item>
        <mat-icon>announcement</mat-icon>
        <span>Notification 2</span>
      </button>
    </mat-menu>

    @if (isUserTypeAgent) {
      <button
        [matMenuTriggerFor]="account_menu"
        mat-icon-button
        [ngClass]="{'router-link-active': showUnderline(['/profile'])}"
        class="nav-bar_icon nav-bar_icon--underline"
        aria-label="Open profile dropdown"
      >
        @if ((profileSummary$ | async)?.avatarThumbnailImageUri; as avatar) {
          <img
            [src]="avatar"
            alt="User avatar"
            appLazyLoad
            width="28"
            height="28"
            class="card__img"
          />
        } @else {
          <mat-icon class="default__icon">account_circle</mat-icon>
        }
      </button>
    }
    <mat-menu
      #account_menu="matMenu"
      class="account-menu"
    >
      <app-card-profile
        avatar="headerProfileAvatar"
        (logOut)="this.handleLogOut()"
      >
      </app-card-profile>
    </mat-menu>

    @if (!isUserTypeAgent) {
      <button
        mat-button
        class="nav-bar_icon nav-bar_icon--underline"
        aria-label="Sign out of your account"
        (click)="handleLogOut()"
      >
        <mat-icon>exit_to_app</mat-icon>
        <span>Sign Out</span>
      </button>
    }
  </div>
</mat-toolbar>
