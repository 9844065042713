export enum PropertySortKey {
  suburb = 'suburb',
  saleDate = 'saleDate',
  salePrice = 'salePrice',
  distance = 'distance',
  listingStartDate = 'listingStartDate',
  listingPrice = 'listingPrice',
  rentalListedDate = 'rentalListedDate',
  weeklyListedPrice = 'weeklyListedPrice',
  listingWithdrawnDate = 'listingWithdrawnDate',
}
