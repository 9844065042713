export class QueryStringParameters {
  private readonly paramsAndValues: string[];
  constructor() {
    this.paramsAndValues = [];
  }

  public push(key: string, value?: Date | number | string | string[] | boolean) {
    if (value === undefined || value === 0 || value === '') {
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((element) => {
        this.paramsAndValues.push([key, encodeURIComponent(element)].join('='));
      });
      return;
    }

    value = value instanceof Date ? value.toJSON() : value.toString();

    this.paramsAndValues.push([key, encodeURIComponent(value)].join('='));
  }

  // eslint-disable-next-line no-invalid-this
  public toString = (): string => this.paramsAndValues.join('&');
}
