import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {AuthenticationService} from '@Core/services/authentication/authentication.service';

@Injectable()
export class AuthorizeInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthenticationService) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isLoggedIn) {
      const authHeader = this.authService.authorizationHeader;
      const authReq = req.clone({headers: req.headers.set('Authorization', authHeader)});
      return next.handle(authReq);
    }

    return next.handle(req);
  }
}
