import {zCoercedEnum} from '@Shared/helpers/zod-helpers/zCoercedEnum';
import {z} from 'zod';
import {vendorSchema} from './vendor-schema';
import {DocumentType} from '@Shared/enums/document-type';

export const propertyDocumentSchema = z.object({
  documentId: z.string(),
  documentName: z.string(),
  documentType: zCoercedEnum(DocumentType),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  archivedOn: z.coerce.date().optional(),
  documentUri: z.string().optional(),
  cmaId: z.string().optional(),
  isDraftDocument: z.boolean().optional(),
  sharedWith: z.union([z.array(vendorSchema), z.null()]).optional(),
});
