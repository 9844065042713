import {UrlBuilder} from '@Shared/classes/url-builder/url-builder';
import {QueryStringParameters} from '@Shared/classes/query-string-parameters/query-string-parameters';

import {environment} from '@Env/environment';

export abstract class ApiEndpointsService {
  constructor(readonly apiGatewayUri: string = environment.apiGatewayUri) {}

  protected createUrlWithQueryParameters(
    action: string,
    queryStringHandler?: (queryStringParameters: QueryStringParameters) => void
  ): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(this.apiGatewayUri, action);
    if (queryStringHandler) {
      queryStringHandler(urlBuilder.queryString);
    }
    return urlBuilder.toString();
  }

  protected createUrlWithPathVariables(action: string, pathVariables: string[] = []): string {
    let encodedPathVariablesUrl = '';
    for (const pathVariable of pathVariables) {
      if (pathVariable !== null) {
        encodedPathVariablesUrl += `/${encodeURIComponent(pathVariable)}`;
      }
    }
    const urlBuilder: UrlBuilder = new UrlBuilder(this.apiGatewayUri, `${action}${encodedPathVariablesUrl}`);
    return urlBuilder.toString();
  }

  protected createUrl(action: string): string {
    const urlBuilder: UrlBuilder = new UrlBuilder(this.apiGatewayUri, action);
    return urlBuilder.toString();
  }
}
