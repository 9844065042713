/* eslint-disable @typescript-eslint/no-explicit-any */

type NameOf<T> = {
  [K in keyof T]: K;
};

export function nameof<T>(name: NameOf<T>): NameOf<T>;
export function nameof<TObject>(obj: TObject, key: keyof TObject): string;
export function nameof<TObject>(key: keyof TObject): string;
export function nameof(key1: any, key2?: any): any {
  return key2 ?? key1;
}
