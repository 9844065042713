import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

const createMouseflowScript = () => {
  const mf = document.createElement('script');
  mf.type = 'text/javascript';
  mf.defer = true;
  mf.src = `${environment.mouseflowSourceURL}`;
  document.getElementsByTagName('head')[0].appendChild(mf);
};

if (environment.production) {
  enableProdMode();
}

if (environment.mouseflowTrackingCodeEnabled) {
  createMouseflowScript();
}

const favicon = document.querySelector<HTMLLinkElement>('#favicon');
if (favicon) {
  favicon.href = environment.favicon;
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
