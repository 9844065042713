import {Inject, Injectable, InjectionToken, NgZone, OnDestroy} from '@angular/core';
import {AutoLogoutConfig} from '@Shared/interfaces/auto-logout-config';
import {fromEvent, interval, merge, Subject, takeUntil} from 'rxjs';
import {AuthenticationService} from '../authentication/authentication.service';
import {RouterEventsService} from '../router-events/router-events.service';
import {Router} from '@angular/router';

export const AUTO_LOGOUT_CONFIG = new InjectionToken<AutoLogoutConfig>('');
const STORE_KEY = 'lastAction';

@Injectable({
  providedIn: 'root',
})
export class AutoLogoutService implements OnDestroy {
  events: string[] = ['click', 'mouseover', 'mouseout', 'keydown', 'keyup', 'keypress'];
  eventStreams = this.events.map((event) => fromEvent(window, event));
  allEvents$ = merge(...this.eventStreams);

  private readonly destroy$ = new Subject<void>();

  constructor(
    private authService: AuthenticationService,
    private ngZone: NgZone,
    @Inject(AUTO_LOGOUT_CONFIG) private config: AutoLogoutConfig,
    private routerEventsService: RouterEventsService,
    private router: Router
  ) {}

  getLastAction(): number {
    const item = localStorage.getItem(STORE_KEY);
    if (item) {
      return parseInt(item, 10);
    } else {
      return Date.now();
    }
  }

  setLastAction(lastAction: number): void {
    localStorage.setItem(STORE_KEY, lastAction.toString());
  }

  startWatching(): void {
    this.ngZone.runOutsideAngular(() => {
      this.check();
      this.initListener();
      this.initInterval();
      this.setLastAction(Date.now());
    });
  }

  initListener(): void {
    this.allEvents$.pipe(takeUntil(this.destroy$)).subscribe(() => this.reset());
  }

  reset(): void {
    this.setLastAction(Date.now());
  }

  initInterval(): void {
    interval(this.config.checkIntervalInMS)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.check());
  }

  check(): void {
    const now = Date.now();
    const timeLeft = this.getLastAction() + this.config.timeOutInMin * 60 * 1000;
    const diff = timeLeft - now;
    const isTimeout = diff < 0;
    const url = this.router.url;
    const shouldLogOut = isTimeout && this.authService.isLoggedIn;
    if (shouldLogOut) {
      this.authService.logout();
      if (url !== '/login' && url !== '/' && url !== '/loader') {
        this.routerEventsService.setPreLoginUrl(url);
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
