/* eslint-disable @typescript-eslint/naming-convention */
export enum AustralianStates {
  VIC = 'Victoria',
  NSW = 'New South Wales',
  QLD = 'Queensland',
  SA = 'South Australia',
  WA = 'Western Australia',
  ACT = 'Australian Capital Territory',
  TAS = 'Tasmania',
  NT = 'Northern Territory',
}
