import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {BackContinueTakeOverComponent} from './back-continue-takeover.component';

@NgModule({
  declarations: [BackContinueTakeOverComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [BackContinueTakeOverComponent],
})
export class BackContinueTakeoverModule {}
