import {ChangeDetectionStrategy, Component, EventEmitter, Output, OnDestroy, OnInit} from '@angular/core';
import {ProfileManagementService} from '@Core/services/profile-management/profile-management-service';
import {MyProfileSummary} from '@Shared/interfaces/my-profile-summary';
import {Observable, Subject} from 'rxjs';

@Component({
  selector: 'app-card-profile',
  templateUrl: './card-profile.component.html',
  styleUrls: ['./card-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardProfileComponent implements OnDestroy, OnInit {
  @Output() logOut = new EventEmitter();

  profileSummary$ = new Observable<Partial<MyProfileSummary>>();

  private readonly destroy$ = new Subject<void>();

  constructor(private readonly profileManagementService: ProfileManagementService) {}

  ngOnInit(): void {
    this.profileSummary$ = this.profileManagementService.profileSummary;
  }

  onLogOut() {
    this.logOut.emit();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
