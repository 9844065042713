import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthorizationService} from '@Core/services/authorization/authorization.service';
import {catchError, map, Observable, of} from 'rxjs';
import {AuthenticationService} from '../../services/authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard {
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly authorizationService: AuthorizationService
  ) {}

  canActivate(): Observable<boolean> {
    if (!this.authService.isLoggedIn) {
      this.router.navigate(['/login']);
      return of(false);
    }

    return this.authorizationService.loadCurrentUserPermissions().pipe(
      map(() => {
        return true;
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === HttpStatusCode.Forbidden) {
          this.router.navigate(['/error'], {
            queryParams: {
              errorCode: 403,
              errorMsg: err.error.detail,
            },
          });
        }
        return of(false);
      })
    );
  }
}
