import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {FeatureFlagsService} from '@Core/services/feature-flag/feature-flags.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  constructor(
    private featureFlagsService: FeatureFlagsService,
    private router: Router
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const requiredFeatureFlag: string = route.data.requiredFeatureFlag as string;

    if (!this.featureFlagsService.isFeatureEnabled(requiredFeatureFlag)) {
      this.router.navigate(['/404']);
      return false;
    }
    return true;
  }
}
