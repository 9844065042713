import {Search} from '../search/search';

export class SavedSearch {
  searchId = '';
  name = '';
  lastRunDate?: Date;
  created: Date = new Date();
  search = new Search();
  searchJson?: string;
  propertyCount?: number;
  mapZoneId?: string;

  constructor(obj?: Partial<SavedSearch>) {
    Object.assign(this, {...obj});

    if (obj?.searchJson) {
      try {
        this.search = new Search(JSON.parse(obj.searchJson));
      } catch {}
    }
  }
}
