import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, UrlTree} from '@angular/router';
import {UserContextService} from '@Core/services/user-context/user-context.service';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserTypeGuard {
  constructor(
    private router: Router,
    private userContextService: UserContextService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    const currentUserType = this.userContextService.getCurrentUserType();

    if (route.data?.requiredUserTypes?.includes(currentUserType)) {
      return of(true);
    } else {
      this.router.navigate(['/error'], {queryParams: {errorCode: 403}});
      return of(false);
    }
  }
}
