import {Injectable} from '@angular/core';
import {LDFlagSet} from 'launchdarkly-js-client-sdk';
import {FeatureConfig} from '@Shared/interfaces/feature-config';
import {AbstractFeatureConfigProvider} from './abstract-feature-config-provider';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LaunchDarklyFeatureConfigProvider implements AbstractFeatureConfigProvider {
  private featureConfig = new BehaviorSubject<FeatureConfig>({});

  getFeatureConfigs(): Observable<FeatureConfig> {
    return this.featureConfig;
  }

  setFeatureConfigs(ldFlagSet: LDFlagSet) {
    // Convert Launch Darkly boolean flags into Nexus FeatureConfig which only supports boolean
    const configs: FeatureConfig = {};
    for (const flagName in ldFlagSet) {
      if (ldFlagSet.hasOwnProperty(flagName) && typeof ldFlagSet[flagName] === 'boolean') {
        configs[flagName] = ldFlagSet[flagName];
      }
    }
    this.featureConfig.next(configs);
  }
}
