import {PropertyImage} from '@Shared/interfaces/property-image';
import {PropertyID} from '@Shared/types/property-id';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ImageCacheService {
  private readonly cache = new Map<PropertyID, Observable<PropertyImage[]>>();

  addImagesToCache(propertyId: PropertyID, images: Observable<PropertyImage[]>): void {
    this.cache.set(propertyId.toUpperCase(), images);
  }

  getImagesFromCache(propertyId: PropertyID): Observable<PropertyImage[]> | undefined {
    return this.cache.get(propertyId.toUpperCase());
  }

  removeImageFromCache(propertyId: PropertyID): boolean {
    return this.cache.delete(propertyId.toUpperCase());
  }
}
