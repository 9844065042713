/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {Observable, throwError} from 'rxjs';
import {catchError, shareReplay} from 'rxjs/operators';
import {ContentType} from '@Shared/enums/content-type';

@Injectable({
  providedIn: 'root',
})
export class ApiHttpService {
  private readonly cache = new Map<string, Observable<any>>();

  constructor(private readonly http: HttpClient) {}

  public get<T>(url: string, useCache = true, useShareReplay = true, contentType?: ContentType): Observable<T> {
    const cachedResponse = this.cache.get(url);
    if (useCache && cachedResponse) {
      return cachedResponse;
    }

    let response = this.http
      .get<T>(url, {
        headers: this.getHeaders(contentType),
        responseType: contentType === ContentType.pdf ? ('blob' as 'json') : 'json',
      })
      .pipe(
        catchError((error) => {
          this.cache.delete(url);
          return throwError(() => error);
        })
      );

    if (useShareReplay) {
      response = response.pipe(shareReplay(1));
    }

    response = response.pipe(
      catchError((error) => {
        this.cache.delete(url);
        return throwError(() => error);
      })
    );

    if (useCache) {
      this.cache.set(url, response);
    }

    return response;
  }

  public patch<T>(url: string, data: any): Observable<T> {
    return this.http
      .patch<T>(url, data, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  public post<T>(url: string, body: any, contentType?: ContentType): Observable<T> {
    let responseType: 'json' | undefined;

    if (contentType) {
      responseType = contentType === ContentType.pdf ? ('blob' as 'json') : 'json';
    }

    return this.http
      .post<T>(url, body, {
        headers: this.getHeaders(),
        responseType,
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  public postFormData<T>(url: string, body: FormData, responseType?: 'json'): Observable<T> {
    return this.http
      .post<T>(url, body, {
        responseType,
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  public putFormData<T>(url: string, body: FormData, responseType?: 'json'): Observable<T> {
    return this.http
      .put<T>(url, body, {
        responseType,
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  public put<T>(url: string, body: any): Observable<T> {
    return this.http
      .put<T>(url, body, {
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  public delete<T>(url: string, body?: any): Observable<T> {
    return this.http
      .delete<T>(url, {
        body,
        headers: this.getHeaders(),
      })
      .pipe(catchError((error) => throwError(() => error)));
  }

  private getHeaders(contentType?: ContentType): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': `application/${contentType ?? 'json'}`,
    });
  }
}
