import {PropertyDocumentType} from '@Shared/interfaces/property-document-type';

export const documentTypesAU: PropertyDocumentType[] = [
  {
    documentTypeName: 'Listing Agreement',
  },
  {
    documentTypeName: 'Sales CMA',
  },
  {
    documentTypeName: 'Marketing',
  },
  {
    documentTypeName: 'Contract of Sale',
  },
  {
    documentTypeName: 'Statement of Information',
    territorialAuthorities: ['Victoria'],
  },
  {
    documentTypeName: 'Consideration of Offers',
    territorialAuthorities: ['Victoria'],
  },
  {
    documentTypeName: 'Acknowledgement of Vendors Asking Price',
    territorialAuthorities: ['Victoria'],
  },
  {
    documentTypeName: 'Certificate of Title',
  },
  {
    documentTypeName: 'Plans/Dealings',
  },
  {
    documentTypeName: 'Other',
  },
];
