import {environment} from '@Env/environment';
import {DOCUMENT} from '@angular/common';
import {Component, AfterViewInit, OnDestroy, Renderer2, Inject, OnInit} from '@angular/core';

import {AuthenticationService} from 'src/app/core/services/authentication/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements AfterViewInit, OnInit, OnDestroy {
  logoSrc = '';
  brandingLabel = '';
  contactInfoLink = '';
  website = '';
  pinIconSrc = '';
  constructor(
    private readonly authService: AuthenticationService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.logoSrc = environment.logoSrc;
    this.pinIconSrc = environment.pinIconSrc;
    this.brandingLabel = environment.brandingLabel;
    this.website = environment.productLink;
    this.contactInfoLink = environment.productLink + '/contact/';
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.document.body, 'login-page--overlay');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'login-page--overlay');
  }

  login() {
    this.authService.initLoginFlow();
  }
}
