import {Pipe, PipeTransform} from '@angular/core';
import {SearchFilter} from '@Shared/classes/search-filter';
/*
 * Gets the length of all truthy values within a Filters object
 * Usage:
 *   value | ReduceFiltersToTruthyLength:Filters
 * Example:
 *   {{ search.Filters | ReduceFiltersToTruthyLength }}
 *   formats to: number
 */
@Pipe({name: 'reduceFiltersToTruthyLength'})
export class ReduceFiltersToTruthyLengthPipe implements PipeTransform {
  transform(searchFilter?: SearchFilter): number {
    if (!searchFilter) {
      return 0;
    }

    const searchFilterCopy = new SearchFilter(searchFilter);
    delete searchFilterCopy.excludePropertyIdentifier;
    delete searchFilterCopy.hasActiveListing;
    delete searchFilterCopy.searchOptions;

    return Object.values(searchFilterCopy).filter((item) => !!item && (!Array.isArray(item) || item.length > 0)).length;
  }
}
