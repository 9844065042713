export enum NotificationType {
  success = 'success',
  error = 'error',
  internalServerError = 'internal-server-error',
  progress = 'progress',
  primary = 'primary',
  discard = 'discard',
  deactivate = 'deactivate',
  delete = 'delete',
  activate = 'activate',
  add = 'add',
  fileNameError = 'filename-error',
  reportSuccess = 'report-success',
  chunkError = 'chunk-error',
}
