import {SearchFilter} from '@Shared/classes/search-filter';
import {FilterSearchText} from '@Shared/enums/filter-search-text';
import {SearchType} from '@Shared/enums/search-type';
import {SuggestionType} from '@Shared/enums/suggestion-type';
import {nameof} from '@Shared/helpers/name-of-helpers/name-of-helpers';
import {MapZone} from '@Shared/interfaces/api-responses/map-zone';
import {CmaComparableProperty} from '@Shared/interfaces/cma-comparable-property';
import {Suggestion} from '@Shared/interfaces/suggestion';
import {isSearch} from '@Shared/type-guards/search-type-guard';
import {AutoCompleteItem} from '@Shared/types/auto-complete-item';
import {Sort} from '@Shared/types/sort';

export class Search {
  displayText = '';
  filters = new SearchFilter();
  suggestions: Suggestion[] = [];
  sort?: Sort;
  searchType?: SearchType = SearchType.address;
  suggestionType?: string;
  searchTerm?: string;

  constructor(obj?: Partial<Search>) {
    Object.assign(this, {
      ...obj,
      filters: new SearchFilter(obj?.filters),
    });
  }

  static formatDisplayText(item: Search): string {
    const search = new Search(item);

    if (search.displayText) {
      return search.displayText;
    }

    if (search.filters.mainSuggestion) {
      search.displayText = search.filters.mainSuggestion.displayText;
      return search.displayText;
    }

    if (search.suggestions.length) {
      const filterNames = search.suggestions.map((name) => name.displayText);
      search.displayText = `${filterNames}`;
    } else {
      const values = Object.values(search.filters).filter((el) => el && el.length !== 0);
      const keys = values
        .map((value) =>
          Object.keys(search.filters)
            .filter((key) => key !== nameof(search.filters, 'searchSpecifiedPropertiesOnly'))
            .find((el) => search.filters[el as keyof SearchFilter] === value)
        )
        .filter((key) => key !== undefined);

      const filterNames = keys
        .map((key) => FilterSearchText[key as keyof typeof FilterSearchText])
        .filter((name) => name !== undefined);
      search.displayText = filterNames.length ? `${filterNames.join(', ')}` : 'Filter Search';
    }
    return search.displayText;
  }

  static addPropertyIdentifiers(
    search: Search,
    properties: CmaComparableProperty[],
    searchSpecifiedPropertiesOnly = false
  ): Search {
    const newSearch = new Search(search);
    newSearch.filters.searchSpecifiedPropertiesOnly = searchSpecifiedPropertiesOnly;

    properties.forEach((item) => {
      newSearch.filters.propertyIdentifiers.push(item.propertyId);
    });

    return newSearch;
  }

  static fromMapZone(mapZone?: MapZone, defaultPolygon?: string): Search {
    return new Search({
      displayText: `Zone Search: ${mapZone?.name ? mapZone.name : '(unsaved zone)'}`,
      filters: new SearchFilter({
        polygon: mapZone?.polygon ?? defaultPolygon,
        mapZoneId: mapZone?.id,
      }),
    });
  }

  static updateSearchFrom(autoCompleteItem: AutoCompleteItem): Search {
    if (isSearch(autoCompleteItem)) {
      return new Search({
        displayText: autoCompleteItem.displayText,
        suggestions: autoCompleteItem.suggestions,
        searchTerm: autoCompleteItem.searchTerm,
        searchType: autoCompleteItem.searchType,
      });
    }

    let searchTerm: string | undefined;
    let searchType: SearchType | undefined;

    if (autoCompleteItem.suggestionType == SuggestionType.PrimaryAddress) {
      searchTerm = autoCompleteItem.primaryAddress;
      searchType = SearchType.primaryAddress;
    }

    return new Search({
      displayText: autoCompleteItem.displayText,
      searchTerm: searchTerm,
      searchType: searchType,
    });
  }
}
