@if (!errorCode) {
  <div class="error__container">
    <div class="error__message">
      <mat-icon class="error__icon">warning</mat-icon>
      <h1>Page Not Found!</h1>
      <p>Sorry, but we were unable to find the page you are looking for. Please check the URL and try again.</p>
      <p>
        <a
          [routerLink]="['/search']"
          routerLinkActive="router-link-active"
          >Navigate to the home page</a
        >
      </p>
    </div>
  </div>
}

@if (errorCode === '500') {
  <div class="error__container">
    <div class="error__message">
      <mat-icon class="error__icon">warning</mat-icon>
      <h1>Something went wrong!</h1>
      <p>{{ errorMsg ?? 'A system error occurred while processing your request.' }}</p>
      <p>
        You can contact support, go back and try again or
        <a
          [routerLink]="['/search']"
          routerLinkActive="router-link-active"
          >navigate to the home page</a
        >
      </p>
    </div>
  </div>
}

@if (errorCode === '403') {
  <div class="error__container">
    <div class="error__message">
      <mat-icon class="error__icon">warning</mat-icon>
      <h1>Access denied</h1>
      <p>{{ errorMsg ?? "You don't have permission to access this page" }}</p>
      <p>Please contact support for further assistance</p>
    </div>
  </div>
}
