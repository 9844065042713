<div class="dialog__container">
  <ng-content select=".dialog__close"></ng-content>
  <div class="dialog__title"><ng-content select=".dialog__heading"></ng-content></div>
  <div class="dialog__content">
    <ng-content select=".dialog__content"></ng-content>
    <ng-content select=".dialog__content-error-message"></ng-content>
  </div>
  <div class="dialog__action">
    <ng-content select=".dialog__checkbox"></ng-content>
    <ng-content select=".dialog__tertiary"></ng-content>
    <ng-content select=".dialog__secondary"></ng-content>
    <ng-content select=".dialog__primary"></ng-content>
  </div>
</div>
