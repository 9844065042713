import {Component, Input} from '@angular/core';
import {ButtonSize, ButtonStatus, ButtonAppearance} from './button-types';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() size: ButtonSize;
  @Input() status: ButtonStatus;
  @Input() appearance: ButtonAppearance;
  @Input() label: string;
  @Input() prefix: string;
  @Input() suffix: string;
  @Input() buttonId = '';
  @Input() disabled = false;
  @Input() type = 'button';

  constructor() {
    this.size = 'default';
    this.status = 'default';
    this.appearance = 'primary';
    this.label = '';
    this.prefix = '';
    this.suffix = '';
  }
}
