import {Injectable} from '@angular/core';
import {FeatureConfig} from '@Shared/interfaces/feature-config';

import {AbstractFeatureConfigProvider} from './abstract-feature-config-provider';
import {map, Observable} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagsService {
  private config!: FeatureConfig;

  constructor(private featureConfigProvider: AbstractFeatureConfigProvider) {
    this.featureConfigProvider
      .getFeatureConfigs()
      .pipe(takeUntilDestroyed())
      .subscribe((config) => {
        this.config = config;
      });
  }

  isFeatureEnabled(flag: string): boolean {
    return this.config?.[flag] ?? false;
  }

  isFeatureEnabled$(flag: string): Observable<boolean> {
    return this.featureConfigProvider.getFeatureConfigs().pipe(
      map((configs) => {
        return configs?.[flag] ?? false;
      })
    );
  }
}
