/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/naming-convention */
export enum CmaStatusType {
  None = 'None',

  Draft = 'Draft',

  DiscardedDraft = 'DiscardedDraft',

  Finalised = 'Finalised',

  Abandoned = 'Abandoned',

  Listed = 'Listed',

  Withdrawn = 'Withdrawn',

  Sold = 'Sold',

  Leased = 'Leased',

  Archived = 'Archived',
}
