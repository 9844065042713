import {DOCUMENT} from '@angular/common';
import {AfterViewInit, Component, Inject, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnInit, AfterViewInit, OnDestroy {
  errorCode?: string;
  errorMsg?: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route?.queryParams.subscribe((params) => {
      this.errorCode = params.errorCode;
      this.errorMsg = params.errorMsg;
    });
  }

  ngAfterViewInit() {
    this.renderer.addClass(this.document.body, 'search-page--overlay');
  }

  ngOnDestroy() {
    this.renderer.removeClass(this.document.body, 'search-page--overlay');
  }
}
