/* eslint-disable max-len */
import {Injectable, inject} from '@angular/core';

import {AuthConfig, NullValidationHandler, OAuthService} from 'angular-oauth2-oidc';
import {environment} from '@Env/environment';
import {Observable, filter, switchMap, of} from 'rxjs';
import {GlobalErrorHandlerService} from '../error-handlers/global-error-handler.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  #globalErrorHandler = inject(GlobalErrorHandlerService);

  private readonly authConfig: AuthConfig = {
    clearHashAfterLogin: true,
    clientId: environment.auth.clientId,
    disableAtHashCheck: true,
    issuer: environment.auth.issuer,
    loginUrl: environment.auth.loginUrl,
    oidc: true,
    redirectUri: window.location.origin + '/loader',
    responseType: 'code',
    scope: `openid offline_access ${environment.auth.instance}/global/profiles ${environment.auth.instance}/global/property ${environment.auth.instance}/global/realestate ${environment.auth.instance}/global/payment ${environment.auth.instance}/global/titleinformation`,
    skipIssuerCheck: true,
    showDebugInformation: !environment.production,
    tokenEndpoint: environment.auth.tokenEndpoint,
  };

  constructor(private readonly oauthService: OAuthService) {}

  get tokenReceivedEvent$(): Observable<boolean> {
    return this.oauthService.events.pipe(
      filter((val) => {
        return val.type === 'token_received';
      }),
      switchMap((_val) => of(true))
    );
  }

  get authorizationHeader(): string {
    return this.oauthService.authorizationHeader();
  }

  get isLoggedIn(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  configure() {
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new NullValidationHandler();
    this.oauthService
      .loadDiscoveryDocument(environment.auth.discoveryDocumentUrl)
      .catch((error) =>
        this.#globalErrorHandler.handleClientError(error, 'Looks like there was a problem. Please reload the page.')
      );
    this.oauthService.strictDiscoveryDocumentValidation = false;
    this.oauthService.setupAutomaticSilentRefresh();

    if (environment.countryCode == 'AU') {
      this.oauthService.customQueryParams = {
        // eslint-disable-next-line
        product_id: environment.productId,
        brand_theme: 'REIPNexus',
      };
    } else if (environment.countryCode == 'NZ') {
      this.oauthService.customQueryParams = {
        // eslint-disable-next-line
        product_id: environment.productId,
        brand_theme: 'NZNexus',
      };
    } else {
      this.oauthService.customQueryParams = {
        // eslint-disable-next-line
        product_id: environment.productId,
      };
    }
  }

  initLoginFlow() {
    this.oauthService.initLoginFlow();
  }

  logout() {
    this.oauthService.logOut();
    localStorage.clear();
    sessionStorage.clear();
  }

  async tryLoginCodeFlow(): Promise<void> {
    await this.oauthService.tryLoginCodeFlow();
  }
}
