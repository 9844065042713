export class CurrentUserContext {
  constructor(
    public tenantId: string,
    public accountId: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public zoneinfo: string,
    public locale: string,
    public userType: string,
    public contactNumber?: string,
    public preferredName?: string,
    public biography?: string,
    public cmaBiography?: string
  ) {}

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  clone(): CurrentUserContext {
    return new CurrentUserContext(
      this.tenantId,
      this.accountId,
      this.firstName,
      this.lastName,
      this.email,
      this.zoneinfo,
      this.locale,
      this.userType,
      this.contactNumber,
      this.preferredName,
      this.biography,
      this.cmaBiography
    );
  }
}
