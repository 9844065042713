<div class="login-page__container">
  <div class="login-page__box">
    <a [href]="website">
      <img
        class="login-page__logo"
        [src]="logoSrc"
        alt="Nexus Logo"
      />
    </a>
    <div class="login-page__details">
      <h1 class="login-page__heading">
        Welcome to <strong>{{ brandingLabel }}</strong>
      </h1>
      <p>Streamlining and transforming the property ecosystem with technology and data</p>
      <button
        routerLinkActive="router-link-active"
        class="login-page__login-button"
        aria-label="Sign in with Valocity"
        (click)="login()"
      >
        <img
          class="login-button--logo"
          [src]="pinIconSrc"
          alt="Logo"
        />
        Sign in to {{ brandingLabel }}
      </button>

      <a
        class="login-page__link"
        [href]="contactInfoLink"
      >
        Not using {{ brandingLabel }} yet? Click here for more information.
      </a>
    </div>
  </div>
</div>
