<a
  [routerLink]="['/profile']"
  routerLinkActive="router-link-active"
  class="menu__item"
>
  @if ((profileSummary$ | async)?.avatarThumbnailImageUri; as avatar) {
    <img
      [src]="avatar"
      alt="User avatar"
      appLazyLoad
      class="menu__img"
    />
  } @else {
    <mat-icon
      class="menu__img"
      #defaulticon
      >account_circle</mat-icon
    >
  }

  <span>My Profile</span>
</a>

<hr />

<button
  class="menu__item menu--button"
  mat-button
  (click)="onLogOut()"
>
  <mat-icon class="menu__icon">logout</mat-icon>
  <span>Sign Out</span>
</button>
