import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {NotificationData} from '@Shared/interfaces/notification-data';
import {NotificationType} from '@Shared/enums/notification-type';
import {ReportAction} from '@Shared/enums/report-action';

@Component({
  selector: 'app-error-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent {
  notificationTypeRef = NotificationType;
  reportActionRef = ReportAction;

  constructor(
    public dialogRef: MatDialogRef<NotificationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: NotificationData
  ) {}

  close() {
    this.dialogRef.close();
  }
}
