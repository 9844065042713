<p
  class="quantum-body-large-regular"
  matSnackBarLabel
>
  {{ data.message }}
</p>
<button
  *ngIf="!data.action"
  matSnackBarAction
  mat-stroked-button
  color="primary"
  (click)="snackBarRef.dismiss()"
>
  <mat-icon>close</mat-icon>
</button>

<button
  matSnackBarAction
  mat-stroked-button
  color="primary"
  class="action-button"
  *ngIf="data.action"
  (click)="onActionClick()"
>
  {{ data.action.title }}
</button>
