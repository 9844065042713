import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {FeatureFlagsService} from '@Core/services/feature-flag/feature-flags.service';
import {FeatureFlags} from '@Shared/enums/feature-flags';
import {Observable, of, Subject, takeUntil} from 'rxjs';

@Directive({
  selector: '[appIsFeatureEnabled]',
})
export class FeatureFlagsDirective implements OnInit, OnDestroy {
  private requiredFlag = '';
  private isHidden = true;
  private readonly destroy$ = new Subject<void>();

  constructor(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagsService: FeatureFlagsService
  ) {}

  @Input() set appIsFeatureEnabled(val: FeatureFlags) {
    if (val) {
      this.requiredFlag = val;
    }
  }

  ngOnInit() {
    this.isFeatureEnabled()
      .pipe(takeUntil(this.destroy$))
      .subscribe((enabled) => {
        if (enabled && this.isHidden) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.isHidden = false;
        } else {
          this.viewContainer.clear();
          this.isHidden = true;
        }
      });
  }

  private isFeatureEnabled(): Observable<boolean> {
    if (this.requiredFlag) {
      return this.featureFlagsService.isFeatureEnabled$(this.requiredFlag);
    } else {
      return of(false);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
