import {ProfileImageType} from '@Shared/enums/profile-image-type';
import {UserImage} from '@Shared/interfaces/api-responses/user-image';

export class UserProfile {
  biography = '';
  company = '';
  contactNumber = '';
  email = '';
  firstName = '';
  lastName = '';
  preferredName = '';
  userImages: UserImage[] = [];
  vendorBiography? = '';
  activatedInPlatform?: string;

  constructor(profile?: Partial<UserProfile>) {
    Object.assign(this, {...profile});
  }

  get userSignatureImageOriginal(): string | undefined {
    return this.userImages.find((r) => r.imageType === ProfileImageType.profileSignature)?.originalUri;
  }

  get userCMAImageThumbnail(): string | undefined {
    return this.userImages.find((r) => r.imageType === ProfileImageType.profileHero)?.thumbnailUri;
  }

  get userCMAImageOriginal(): string | undefined {
    return this.userImages.find((r) => r.imageType === ProfileImageType.profileHero)?.originalUri;
  }

  get userAvatarImageThumbnail(): string | undefined {
    return this.userImages.find((r) => r.imageType === ProfileImageType.profile)?.thumbnailUri;
  }

  get companyLogoThumbnail(): string | undefined {
    return this.userImages.find((r) => r.imageType === ProfileImageType.companyLogo)?.thumbnailUri;
  }

  get companyLogoOriginal(): string | undefined {
    return this.userImages.find((r) => r.imageType === ProfileImageType.companyLogo)?.originalUri;
  }

  updateUserImages(newImage: UserImage): void {
    const currentImage = this.userImages.find((r) => r.imageType === newImage.imageType);
    if (currentImage) {
      this.userImages.forEach((element) => {
        if (element.id === newImage.id && element.imageType === newImage.imageType) {
          element.thumbnailUri = newImage.thumbnailUri;
          element.originalUri = newImage.originalUri;
        }
      });
    } else {
      this.userImages.push(newImage);
    }
  }
}
