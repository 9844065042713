import {LayerType} from '@Env/enums/layerType';
import {MapLayerName} from '@Env/enums/map-layer-name';
import {CustomOverlayMap} from '@Features/search/components/interfaces/custom-overlay-map';
import {AustralianStates} from '@Shared/enums/locations';

export const customOverlaysAU = (geoServerBaseUri: string): CustomOverlayMap[] => {
  return [
    {
      name: 'Property Info',
      mapOverlayConfiguration: [
        {
          name: MapLayerName.localSchools,
          disabled: false,
          mapOverlayConfiguration: [
            {
              name: '',
              url: '',
              layers: '',
              type: LayerType.localSchools,
              overlaySelected: false,
              zIndex: 1000,
            },
          ],
        },
        {
          name: MapLayerName.planningDevelopment,
          disabled: true,
          mapOverlayConfiguration: [
            {
              name: '',
              url: '',
              layers: '',
              type: LayerType.planningDevelopment,
              overlaySelected: false,
              zIndex: 1000,
            },
          ],
        },
        {
          name: 'Land Parcels',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_parcel',
              type: LayerType.tiles,
              overlaySelected: false,
              zIndex: 1000,
              minZoom: 10,
            },
          ],
        },
        {
          name: 'Land Area',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_landarea',
              type: LayerType.tiles,
              overlaySelected: false,
              buffer: 100,
              wmsOptions: {styles: 'Nexus_landarea', env: ''},
              zIndex: 2000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Dimensions',
          geomProperty: 'geom',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_parcel',
              type: LayerType.boundingBoxFeature,
              overlaySelected: false,
              polyLineOptions: {color: `#1AA0AE`, fillOpacity: 0, weight: 1, showMeasurements: true},
              minZoom: 19,
              boundingBoxFeatureLayers: [],
              cqlFilter: "parcel_id<>''",
            },
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_parcel',
              type: LayerType.tiles,
              overlaySelected: false,
              zIndex: 1000,
              minZoom: 19,
            },
          ],
        },
        {
          name: 'Last Sale Price & Sale Date',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_salesprice',
              type: LayerType.tiles,
              overlaySelected: false,
              buffer: 100,
              wmsOptions: {styles: 'Nexus_saleprice_date', env: ''},
              zIndex: 2000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Title Number',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_centroids',
              type: LayerType.tiles,
              overlaySelected: false,
              buffer: 100,
              wmsOptions: {styles: 'Nexus_label_title_number', env: 'property:parcel_id'},
              zIndex: 2000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Street Number',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_streets',
              type: LayerType.tiles,
              overlaySelected: false,
              buffer: 100,
              wmsOptions: {styles: '', env: 'property:street_num'},
              zIndex: 2000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Land Use',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_landuse',
              type: LayerType.tiles,
              overlaySelected: false,
              buffer: 100,
              wmsOptions: {styles: 'Nexus_label_landuse', env: 'property:psma_description'},
              zIndex: 2000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 10,
            },
          ],
        },
        {
          name: 'Owner Names',
          states: [AustralianStates.VIC, AustralianStates.WA],
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_ownernames',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: 'Nexus_label', env: 'property:ownerslist'},
              zIndex: 2000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Lot Plan',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:aus_centroids',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:lot'},
              zIndex: 2000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 15,
            },
          ],
        },
      ],
    },
    {
      name: 'Properties Sold',
      mapOverlayConfiguration: [
        {
          name: 'Sold (last 0-3 mths)',
          color: 'BDA7D7',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:sale_last3;color:BDA7D7'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Sold (last 3-6 mths)',
          color: 'C163AB',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:sale_36m;color:C163AB'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Sold (last 6-12 mths)',
          color: 'F3963B',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:sale_612m;color:F3963B'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Sold (last 12-24 mths)',
          color: 'CC3300',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:sale_1224m;color:CC3300'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Sold (last 24-36 mths)',
          color: '4CAC8F',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:sale_2436m;color:4CAC8F'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
        {
          name: 'Multiple Sales (last 3 years)',
          color: '0F5A62',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:multisale;color:0F5A62'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
      ],
    },
    {
      name: 'Properties Listed',
      mapOverlayConfiguration: [
        {
          name: 'For Sale (last 6 mths)',
          color: '273347',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:prop6m;color:273347'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
        {
          name: 'For Rent (last 6 mths)',
          color: '5E3C84',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'aus_properties',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: '', env: 'property:rental6m;color:5E3C84'},
              zIndex: 1000,
              minZoom: 15,
            },
          ],
        },
      ],
    },
    {
      name: 'Area Research',
      mapOverlayConfiguration: [
        {
          name: 'Contours',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:Contours',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: 'Nexus_contours', env: 'colorlow:CC3300;colorhigh:1AA0AE'},
              zIndex: 1000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 4,
            },
          ],
        },
        {
          name: 'Local Authority',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:LGA',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: 'Nexus_label_boundary', env: 'property:lga_name;fontSize:28;boundaryColor:364763'},
              zIndex: 3000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 8,
            },
          ],
        },
        {
          name: 'Suburb',
          mapOverlayConfiguration: [
            {
              name: '',
              url: `${geoServerBaseUri}/Australia/wms`,
              layers: 'Australia:SAL',
              type: LayerType.tiles,
              overlaySelected: false,
              wmsOptions: {styles: 'Nexus_label_boundary', env: 'property:sal_name;fontSize:18'},
              zIndex: 3000,
              aerialLabelColor: 'labelColor:FFFFFF;haloColor:000000',
              minZoom: 10,
            },
          ],
        },
      ],
    },
  ];
};
