import {nonNumericRegex} from '@Shared/constants/regular-expressions';

export const parseNumber = (value?: string | number): number | undefined => {
  if (value === undefined || value === null) {
    return;
  }

  const cleanValue = value.toString().replace(nonNumericRegex, '').trim();

  if (cleanValue === '') {
    return;
  }

  const result = Number(cleanValue);

  return isNaN(result) ? undefined : result;
};
