import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AuthorizeGuard} from '@Core/guards/authorize/authorize.guard';
import {ErrorPageComponent} from '@Pages/error-page/error-page.component';
import {LoaderComponent} from '@Components/molecules/loader/loader.component';
import {LoginComponent} from '@Pages/login/login.component';
import {UserTypeGuard} from '@Core/guards/user-type/user-type.guard';
import {userTypes} from '@Shared/constants/user-types';
import {environment} from '@Env/environment';
import {FeatureFlagGuard} from '@Core/guards/feature-flag/feature-flag.guard';
import {FeatureFlags} from '@Shared/enums/feature-flags';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'property-summary',
    loadChildren: () => import('./pages/property-summary/property-summary.module').then((m) => m.PropertySummaryModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'profile',
    loadChildren: () =>
      import('./pages/profile-management/profile-management.module').then((m) => m.ProfileManagementModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'search',
    loadChildren: () => import('./pages/search/search.module').then((m) => m.SearchModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'search-results',
    loadChildren: () => import('./pages/search-results/search-results.module').then((m) => m.SearchResultsModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'comparative-market-analysis',
    loadChildren: () => import('./pages/cma/cma.module').then((m) => m.CmaModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender, userTypes.admin]},
    path: 'manage',
    loadChildren: () => import('./pages/user-management/user-management.module').then((m) => m.UserManagementModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.vendor]},
    path: 'vendor-dashboard',
    loadChildren: () => import('./pages/vendor-dashboard/vendor-dashboard.module').then((m) => m.VendorDashboardModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.vendor]},
    path: 'vendor-portal/dashboard',
    loadChildren: () => import('./pages/vendor-dashboard/vendor-dashboard.module').then((m) => m.VendorDashboardModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.vendor]},
    path: 'vendor-portal/comparative-market-analyses',
    loadChildren: () => import('./pages/vendor-property/vendor-property.module').then((m) => m.VendorPropertyModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'statement-of-information',
    loadChildren: () => import('./pages/statement-of-information/soi.module').then((m) => m.SOIModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard],
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomeModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, UserTypeGuard],
    data: {requiredUserTypes: [userTypes.realEstateAgent, userTypes.lender]},
    path: 'property-summary/:propertyId/order',
    loadChildren: () =>
      import('./pages/document-ordering/document-ordering.module').then((m) => m.DocumentOrderingModule),
    title: environment.brandingLabel,
  },
  {
    canActivate: [AuthorizeGuard, FeatureFlagGuard, UserTypeGuard],
    data: {
      requiredFeatureFlag: FeatureFlags.cpmsEnabled,
      requiredUserTypes: [userTypes.admin],
    },
    path: 'cpms',
    loadChildren: () =>
      import('./pages/client-product-management-system/client-product-management-system.module').then(
        (m) => m.ClientProductManagementSystemModule
      ),
  },
  {
    path: '404',
    component: ErrorPageComponent,
    title: environment.brandingLabel,
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    title: environment.brandingLabel,
  },
  {
    path: 'loader',
    component: LoaderComponent,
    title: environment.brandingLabel,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
    title: environment.brandingLabel,
  },
  {
    path: '**',
    redirectTo: '/404',
    pathMatch: 'full',
    title: environment.brandingLabel,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
