import {NgModule} from '@angular/core';
import {AutoCompletePositionDirective} from './autocomplete-position/autocomplete-position.directive';
import {CurrencyInputDirective} from './currency-input/currency-input.directive';
import {DataDisclaimerDirective} from './data-disclaimer/data-disclaimer.directive';
import {ThrottleClickDirective} from './throttle-click/throttle-click.directive';
import {DragDropDirective} from './drag-drop/drag-drop.directive';
import {FeatureFlagsDirective} from './feature-flag/feature-flags.directive';
import {FormErrorContainerDirective} from './form-errors/form-error-container.directive';
import {FormErrorsDirective} from './form-errors/form-errors.directive';
import {LazyLoadDirective} from './lazy-load/lazy-load.directive';
import {LimitLinesDirective} from './limit-lines/limit-lines.directive';
import {NumberOnlyDirective} from './number-only/number-only.directive';
import {HasPermissionDirective} from './permissions/has-permission.directive';
import {TrimWhitespaceDirective} from './trim-whitespace/trim-whitespace.directive';
import {SwipeDirective} from './swipe/swipe.directive';
import {ObserveVisibilityDirective} from './observe-visibility/observe-visibility.directive';
import {DisableMapPropagationDirective} from './disable-map-propagation/disable-map-propagation.directive';
import {ForbiddenCharactersDirective} from './forbidden-characters/forbidden-characters.directive';

@NgModule({
  declarations: [
    CurrencyInputDirective,
    DataDisclaimerDirective,
    DragDropDirective,
    FeatureFlagsDirective,
    FormErrorsDirective,
    FormErrorContainerDirective,
    LazyLoadDirective,
    LimitLinesDirective,
    NumberOnlyDirective,
    HasPermissionDirective,
    TrimWhitespaceDirective,
    SwipeDirective,
    ObserveVisibilityDirective,
    DisableMapPropagationDirective,
  ],
  imports: [ThrottleClickDirective, AutoCompletePositionDirective, ForbiddenCharactersDirective],
  exports: [
    AutoCompletePositionDirective,
    CurrencyInputDirective,
    DataDisclaimerDirective,
    ThrottleClickDirective,
    DragDropDirective,
    FeatureFlagsDirective,
    FormErrorsDirective,
    FormErrorContainerDirective,
    LazyLoadDirective,
    LimitLinesDirective,
    NumberOnlyDirective,
    HasPermissionDirective,
    TrimWhitespaceDirective,
    SwipeDirective,
    ObserveVisibilityDirective,
    DisableMapPropagationDirective,
    ForbiddenCharactersDirective,
  ],
})
export class DirectivesModule {}
