import {Property} from '@Shared/classes/property/property';
import {SearchSummary} from '@Shared/classes/search-summary/search-summary';
import {AustralianStates} from '@Shared/enums/locations';
import {nameof} from '@Shared/helpers/name-of-helpers/name-of-helpers';

export const auPropertyData = {
  propertySummarySection: [
    nameof<Property>('bedrooms'),
    nameof<Property>('bathrooms'),
    nameof<Property>('carSpaces'),
    nameof<Property>('landArea'),
    nameof<Property>('floorArea'),
    nameof<Property>('yearBuilt'),
    nameof<Property>('saleDate'),
    nameof<Property>('salePrice'),
    nameof<Property>('settlementDate'),
    nameof<Property>('landValue'),
    nameof<Property>('landUse'),
    nameof<Property>('landZone'),
    nameof<Property>('localAuthority'),
    nameof<Property>('propertyType'),
    nameof<Property>('legalDescription'),
    nameof<Property>('owners'),
    nameof<Property>('marketingContacts'),
  ],
  summarySectionStateSpecificTiles: [
    {
      state: AustralianStates.QLD,
      tiles: [nameof<Property>('folioNumber')],
    },
  ],
  propertyInformationSection: [],
  cardTileFallback: [
    nameof<Property>('bedrooms'),
    nameof<Property>('bathrooms'),
    nameof<Property>('carSpaces'),
    nameof<Property>('landArea'),
    nameof<Property>('floorArea'),
    nameof<Property>('yearBuilt'),
    nameof<Property>('saleDate'),
    nameof<Property>('salePrice'),
    nameof<Property>('capitalValue'),
    nameof<Property>('landValue'),
    nameof<Property>('roofConstruction'),
    nameof<Property>('wallConstruction'),
  ],
  cardTileSearchPage: [
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('yearBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('carSpaces'),
    nameof<SearchSummary>('landUse'),
  ],
  cardTileCMAPropertiesSold: [
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('yearBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('carSpaces'),
    nameof<SearchSummary>('landUse'),
    nameof<SearchSummary>('distanceFrom'),
  ],
  cardTileCMAPropertiesOnMarket: [
    nameof<SearchSummary>('listingStartDate'),
    nameof<SearchSummary>('listingPriceView'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('yearBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('carSpaces'),
    nameof<SearchSummary>('landUse'),
    nameof<SearchSummary>('distanceFrom'),
  ],
  cardTileRentalCMAPropertiesForLease: [
    nameof<SearchSummary>('weeklyListedPrice'),
    nameof<SearchSummary>('rentalListedDate'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('yearBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('carSpaces'),
    nameof<SearchSummary>('landUse'),
    nameof<SearchSummary>('distanceFrom'),
  ],
  cardTileSalesCMAProperty: [
    nameof<SearchSummary>('saleDate'),
    nameof<SearchSummary>('salePrice'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('yearBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('carSpaces'),
    nameof<SearchSummary>('landUse'),
  ],
  cardTileRentalCMAProperty: [
    nameof<SearchSummary>('weeklyListedPrice'),
    nameof<SearchSummary>('listingStartDate'),
    nameof<SearchSummary>('landValue'),
    nameof<SearchSummary>('landArea'),
    nameof<SearchSummary>('floorArea'),
    nameof<SearchSummary>('yearBuilt'),
    nameof<SearchSummary>('bedrooms'),
    nameof<SearchSummary>('bathrooms'),
    nameof<SearchSummary>('carSpaces'),
    nameof<SearchSummary>('landUse'),
  ],
  listViewSearchPage: {
    largeScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('state'),
      nameof<SearchSummary>('localAuthority'),
      nameof<SearchSummary>('landValue'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('bathrooms'),
      nameof<SearchSummary>('yearBuilt'),
      nameof<SearchSummary>('saleDate'),
      nameof<SearchSummary>('salePrice'),
    ],
    mediumScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('state'),
      nameof<SearchSummary>('localAuthority'),
      nameof<SearchSummary>('landValue'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('bathrooms'),
      nameof<SearchSummary>('yearBuilt'),
    ],
    mediumSmallScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('state'),
      nameof<SearchSummary>('localAuthority'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('bedrooms'),
    ],
    smallScreens: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('state'),
      nameof<SearchSummary>('localAuthority'),
    ],
  },
  listViewSearchPageV2: {
    default: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('saleDate'),
      nameof<SearchSummary>('salePrice'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('yearBuilt'),
      nameof<SearchSummary>('owners'),
    ],
    forSale: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('listingStartDate'),
      nameof<SearchSummary>('listingPrice'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('yearBuilt'),
      nameof<SearchSummary>('owners'),
    ],
    withdrawn: [
      nameof<SearchSummary>('address'),
      nameof<SearchSummary>('suburb'),
      nameof<SearchSummary>('listingWithdrawnDate'),
      nameof<SearchSummary>('listingStartDate'),
      nameof<SearchSummary>('listingPrice'),
      nameof<SearchSummary>('bedrooms'),
      nameof<SearchSummary>('landArea'),
      nameof<SearchSummary>('floorArea'),
      nameof<SearchSummary>('yearBuilt'),
      nameof<SearchSummary>('owners'),
    ],
  },
};
