import {RouterEventsService} from '@Core/services/router-events/router-events.service';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscription, interval} from 'rxjs';

import {AuthenticationService} from 'src/app/core/services/authentication/authentication.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  providers: [{provide: Window, useValue: window}],
})
export class LoaderComponent implements OnInit {
  private checkInterval$: Subscription | undefined;
  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private readonly routerEventsService: RouterEventsService,
    private readonly window: Window
  ) {}

  ngOnInit(): void {
    const preLoginUrl = this.routerEventsService.getPreLoginUrl();
    if (this.authService.isLoggedIn) {
      this.redirectLoggedInUser(preLoginUrl);
    } else {
      let retries = 0;
      this.checkInterval$ = interval(2000).subscribe({
        next: () => {
          if (this.authService.isLoggedIn) {
            this.redirectLoggedInUser(preLoginUrl);
          } else if (retries + 1 > 3) {
            this.navigate('/login');
          }
          retries++;
        },
      });
    }
  }

  redirectLoggedInUser(preLoginUrl?: string): void {
    if (preLoginUrl) {
      this.navigate(preLoginUrl);
    } else {
      this.navigate('/home');
    }
  }

  private navigate(url: string): void {
    const fullPath = new URL(this.window.location.origin + url);
    const params = Object.fromEntries(fullPath.searchParams);
    this.checkInterval$?.unsubscribe();
    this.router.navigate([fullPath.pathname], {queryParams: params});
  }
}
