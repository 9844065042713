<div class="error">
  <div class="error__title">
    <mat-icon
      class="icon icon__error"
      fontSet="material-icons-outlined"
      >{{ bannerIcon }}</mat-icon
    >
    <p class="quantum-body-large-bold">{{ title }}</p>
  </div>
  <p class="error__text">{{ text }}</p>
</div>
