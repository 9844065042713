// Angular Modules
import {BrowserModule} from '@angular/platform-browser';
import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceWorkerModule} from '@angular/service-worker';
// Angular Material Modules
import {LayoutModule} from '@angular/cdk/layout';

// Application Factories

// Application Modules
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from '@Core/core.module';

// Application Components
import {AppComponent} from './app.component';
import {environment} from '../environments/environment';
import {MatIconModule} from '@angular/material/icon';
import {ErrorPageComponent} from '@Pages/error-page/error-page.component';
import {MonitoringService} from '@Core/services/monitoring/monitoring.service';
import {GlobalErrorHandlerService} from '@Core/services/error-handlers/global-error-handler.service';
import {ENVIRONMENT, getEnvironment} from '@Env/environment.provider';
import {LoginComponent} from '@Pages/login/login.component';
import {BackContinueTakeoverModule} from '@Components/templates/takeovers/back-continue-takeover/back-continue-takeover.module';
import {MatButtonModule} from '@angular/material/button';
import {AUTO_LOGOUT_CONFIG} from '@Core/services/auto-logout/auto-logout.service';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';

export const prefersReducedMotion = (): boolean => {
  const mediaQueryList = window.matchMedia('(prefers-reduced-motion)');

  return mediaQueryList.matches;
};
@NgModule({
  declarations: [AppComponent, ErrorPageComponent, LoginComponent],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations: prefersReducedMotion(),
    }),
    BrowserModule,
    CoreModule.forRoot(),
    LayoutModule,
    MatIconModule,
    MatButtonModule,
    BackContinueTakeoverModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    MatToolbarModule,
    MatMenuModule,
  ],
  providers: [
    MonitoringService,
    {provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    {provide: ENVIRONMENT, useFactory: getEnvironment},
    {provide: AUTO_LOGOUT_CONFIG, useValue: {timeOutInMin: 30, checkIntervalInMS: 15000}},
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
