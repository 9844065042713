export const compareStrings = (a?: string, b?: string): boolean => {
  if (!a || !b) {
    return false;
  }

  return a === b;
};

export const commonDialogConfig = {
  disableClose: true,
  hasBackdrop: true,
  closeOnNavigation: true,
  autoFocus: 'dialog',
};
