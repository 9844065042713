export enum ProfilePermissions {
  viewOwnClient = 'ViewOwnClient',
  manageOwnClient = 'ManageOwnClient',
  viewUsers = 'ViewUsers',
  manageUsers = 'ManageUsers',
  viewRoles = 'ViewRoles',
  manageRoles = 'ManageRoles',
  viewCentres = 'ViewCentres',
  manageCentres = 'ManageCentres',
  assignCMAs = 'AssignCMAs',
  viewClients = 'ViewClients',
}
