import {Injectable, OnDestroy} from '@angular/core';
import {NavigationCancel, NavigationEnd, Router} from '@angular/router';
import {Subject, filter, takeUntil} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RouterEventsService implements OnDestroy {
  currentUrl = '';
  previousUrl: string | undefined;
  private localStorageString = 'preLoginUrl';
  private readonly destroy$ = new Subject<void>();

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events
      .pipe(takeUntil(this.destroy$))
      .pipe(filter((events) => events instanceof NavigationEnd))
      .subscribe((event) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = (event as NavigationEnd).url;
      });
  }

  listenForEvents() {
    this.router.events
      .pipe(
        filter((events) => events instanceof NavigationCancel),
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        const url = (event as NavigationCancel).url;

        if (url !== '/') {
          localStorage.setItem(this.localStorageString, (event as NavigationCancel).url);
        }
      });
  }

  setPreLoginUrl(url: string) {
    localStorage.setItem(this.localStorageString, url);
  }

  getPreLoginUrl(): string | undefined {
    const preLoginUrl = localStorage.getItem(this.localStorageString) ?? undefined;

    if (preLoginUrl) {
      localStorage.removeItem(this.localStorageString);
    }

    return preLoginUrl;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
