import {Injectable} from '@angular/core';
import {FeatureConfig} from '@Shared/interfaces/feature-config';
import {AbstractFeatureConfigProvider} from './abstract-feature-config-provider';
import {EnvironmentFeatureConfigProvider} from './environment-feature-config-provider';
import {LaunchDarklyFeatureConfigProvider} from './launch-darkly-feature-config-provider';
import {BehaviorSubject, Observable, combineLatest} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable()
export class FeatureConfigProvider implements AbstractFeatureConfigProvider {
  private featureConfig = new BehaviorSubject<FeatureConfig>({});

  constructor(
    featureEnvironmentConfigProvider: EnvironmentFeatureConfigProvider,
    launchDarklyFeatureConfigProvider: LaunchDarklyFeatureConfigProvider
  ) {
    combineLatest({
      envConfig: featureEnvironmentConfigProvider.getFeatureConfigs(),
      launchDarklyConfig: launchDarklyFeatureConfigProvider.getFeatureConfigs(),
    })
      .pipe(takeUntilDestroyed())
      .subscribe((config) => {
        this.featureConfig.next({...config.envConfig, ...config.launchDarklyConfig});
      });
  }

  getFeatureConfigs(): Observable<FeatureConfig> {
    return this.featureConfig;
  }
}
