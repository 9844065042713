import {Button} from '@Shared/component-library/components/atoms/buttons/action-button/button-types';
import {MessageFormat} from '@Shared/enums/message-format';
import {NotificationType} from '@Shared/enums/notification-type';

interface INotificationData {
  notificationType: NotificationType;
  message: string;
  title?: string;
  messageFormat?: MessageFormat;
  buttons?: Button[];
  errorBanner?: ErrorBanner;
}

export interface ErrorBanner {
  title: string;
  text: string;
  canShow: boolean;
}

export class NotificationData implements INotificationData {
  readonly notificationType: NotificationType;
  readonly message: string;
  readonly messageFormat: MessageFormat;
  readonly errorCode?: number;
  readonly title?: string;
  readonly buttons?: Button[];
  readonly errorBanner?: ErrorBanner;

  constructor(data: INotificationData) {
    this.notificationType = data.notificationType;
    this.message = data.message;
    this.title = data.title;
    this.messageFormat = data.messageFormat ?? MessageFormat.text;
    this.buttons = data.buttons;
    this.errorBanner = data.errorBanner;
  }
}
