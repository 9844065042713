import {PropertySection} from '@Shared/interfaces/property-section';
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  sections$: Observable<PropertySection[]>;

  private sections = new Subject<PropertySection[]>();

  constructor() {
    this.sections$ = this.sections;
  }

  updatePropertySections(propertySections: PropertySection[]) {
    this.sections.next(propertySections);
  }
}
