import {Search} from '@Shared/classes/search/search';
import {CmaFilterType} from '../../enums/CmaFilterType';

export class CmaFilter {
  filterType!: CmaFilterType;
  serialisedFilter?: string;
  search?: Search;

  constructor(filter?: CmaFilter) {
    if (filter) {
      this.filterType = filter.filterType;
      this.serialisedFilter = filter.serialisedFilter;
    }
    this.initialiseSearch();
  }

  initialiseSearch(): void {
    this.search = this.serialisedFilter ? new Search(JSON.parse(this.serialisedFilter)) : new Search();
  }

  toSaveVersion(): CmaFilter {
    const saveVersion = new CmaFilter();
    saveVersion.serialisedFilter = this.search ? JSON.stringify(this.search) : this.serialisedFilter;
    saveVersion.filterType = this.filterType;
    delete saveVersion.search;
    return saveVersion;
  }
}
