@if (showHeader) {
  <app-header></app-header>
}
<mat-toolbar
  [matMenuTriggerFor]="sectionsMenu"
  id="topMenu"
>
  <span>Jump To</span>
  <span>Section</span>
</mat-toolbar>
<mat-menu
  #sectionsMenu="matMenu"
  class="sectionsMobileMenu"
>
  @for (section of sections$ | async; track section) {
    @if (section.visible) {
      <button
        mat-menu-item
        (click)="scrollToSection(section.sectionName)"
      >
        {{ section.title }}
      </button>
    }
  }
</mat-menu>
<main class="main-content_container">
  <router-outlet></router-outlet>
</main>
