import {Injectable, OnDestroy, inject} from '@angular/core';
import {initialize, LDClient, LDContext} from 'launchdarkly-js-client-sdk';
import {AuthenticationService} from '../authentication/authentication.service';
import {Subject} from 'rxjs';
import {UserContextService} from '../user-context/user-context.service';
import {CurrentUserContext} from '@Shared/classes/user-context/current-user-context';
import {LaunchDarklyFeatureConfigProvider} from './launch-darkly-feature-config-provider';
import {ENVIRONMENT} from '@Env/environment.provider';
import {EnvironmentConfiguration} from '@Env/interfaces/environment-configuration';

@Injectable({
  providedIn: 'root', // LaunchDarkly Client SDK instance must be a Singleton.
})
export class LaunchDarklyInitializer implements OnDestroy {
  private userContext: CurrentUserContext | null = null;

  #ldClient: LDClient | null = null;
  #environment: EnvironmentConfiguration = inject(ENVIRONMENT);

  private readonly destroy$ = new Subject();

  constructor(
    private authService: AuthenticationService,
    private userContextService: UserContextService,
    private launchDarklyFeatureConfigProvider: LaunchDarklyFeatureConfigProvider
  ) {
    if (this.userContextService.getCurrentUser() !== null) {
      // We already have an authenticated user, initialize LaunchDarkly immediately.
      this.initLaunchDarkly();
    } else {
      // We need a successfully authenticated user's details to initialize LaunchDarkly.
      this.authService.tokenReceivedEvent$.subscribe(() => {
        this.initLaunchDarkly();
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();

    if (this.ldClient !== null) {
      this.ldClient.close();
    }
  }

  private initLaunchDarkly(): boolean {
    if (this.#environment.launchDarklyClientSDKKey) {
      this.ldClient = initialize(this.#environment.launchDarklyClientSDKKey, this.createLaunchDarklyContext());
      if (this.ldClient !== null) {
        this.ldClient.on('ready', () => this.onLaunchDarklyReady());

        /* Registering for LDClient on change events means a live streaming connection will be maintained by LaunchDarkly. Not initially req'd.
        this.ldClient.on('change', (ldFlags: LDFlagChangeset) => this.onLaunchDarklyChange(ldFlags));
        */

        return true;
      } else {
        console.log('[LaunchDarkly] Initialization failed.');
      }
    } else {
      console.log('[LaunchDarkly] No client SDK key provided.');
    }

    return false;
  }

  private get ldClient(): LDClient | null {
    return this.#ldClient;
  }

  private set ldClient(value: LDClient | null) {
    this.#ldClient = value;
  }

  private createLaunchDarklyContext(): LDContext {
    this.userContext = this.userContextService.getCurrentUser();
    const ldContext: LDContext = {
      // Required by Launch Darkly.
      kind: 'user',
      // Must be unique for each LD user context.
      key: this.userContext?.accountId,

      // These are Valocity Nexus specific context values, and we can add to them as req'd.
      name: this.userContext?.fullName,
      email: this.userContext?.email,
      tenantId: this.userContext?.tenantId,
      userType: this.userContext?.userType,
    };

    return ldContext;
  }

  private onLaunchDarklyReady(): void {
    if (this.ldClient !== null) {
      this.launchDarklyFeatureConfigProvider.setFeatureConfigs(this.ldClient.allFlags());
    }
  }

  /* Handler for live streaming of events from LaunchDarkly. Not initially req'd.
  private onLaunchDarklyChange(ldFlagsChanged: LDFlagChangeset): void {
    for (const name in ldFlagsChanged) {
      if (ldFlagsChanged.hasOwnProperty(name)) {
        this.ldFlagSet[name] = ldFlagsChanged[name].current;
      }
    }
  }
  */
}
