import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthorizationService} from '@Core/services/authorization/authorization.service';

@Directive({
  selector: '[appHasPermission]',
})
export class HasPermissionDirective implements OnInit {
  private permissions: string[] = [];
  constructor(
    /* eslint-disable @typescript-eslint/no-explicit-any */
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationService: AuthorizationService
  ) {}

  @Input()
  set appHasPermission(val: string[]) {
    this.permissions = val;
  }

  ngOnInit() {
    this.authorizationService.hasPermission(this.permissions).subscribe((isAuthorized) => {
      if (isAuthorized) {
        this.createView();
      } else {
        this.viewContainer.clear();
      }
    });
  }

  private createView() {
    if (!this.viewContainer.length) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
